import React from 'react'
import Intro from 'Components/about/Intro'
import Story from 'Components/about/Story'
import Team from 'Components/about/Team'
import Ribbon from 'Components/about/Ribbon'
import Awards from 'Components/common/Awards'
import { graphql } from 'gatsby'

interface IProps {
  data: {
    stringsJson: any
  }
}

const AboutPage: React.FC<IProps> = ({
  data: {
    stringsJson: { about },
  },
}) => (
  <>
    <Intro intro={about.intro} />
    <Story story={about.story} />
    <Ribbon />
    <Team team={about.team} />
    <Awards awards={about.awards} className="py-8" />
  </>
)

export default AboutPage

export const query = graphql`
  query AboutQuery($lang: String!) {
    stringsJson(about: { lang: { eq: $lang } }) {
      ...About
    }
  }
`
